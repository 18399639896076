import React from "react";
import ReactDOM from "react-dom";
import NeuApp from "./NeuApp";
import reportWebVitals from "./reportWebVitals";
ReactDOM.render(
  <React.StrictMode>
      <NeuApp />
    
  </React.StrictMode>,
  document.getElementById("root")
);

reportWebVitals();
