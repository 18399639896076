import React from "react";

import { Anchor, Box, Header, Image, Menu, ResponsiveContext } from "grommet";
import { Chat, Menu as MenuIcon, Code, Paint } from "grommet-icons";


const ourTeam = [
  {
    label: (
      <Box pad="small" alignSelf="center" background={"black"}>
        Abbas Tolgay Yılmaz
      </Box>
    ),
    color: "black",
    href: "http://linktr.ee/streamerd",
    target: "_blank",
    icon: (
      <Box pad="small">
        <Code size="medium" />
      </Box>
    ),
  },
 
  {
    label: (
      <Box pad="small" alignSelf="center" background={"#98bdff"}>
        Bobby Martin
      </Box>
    ),
    color: "black",
    href: "http://www.linkedin.com/in/bobby-martin-uxdesigner",
    target: "_blank",
    icon: (
      <Box pad="small">
        <Paint size="medium" />
      </Box>
    ),
  },
  {
    label: (
      <Box pad="small" alignSelf="center" background={"orange"}>
        Evrim Polat
      </Box>
    ),
    color: "black",
    href: "https://www.linkedin.com/in/evrim-polat-0b5766110/",
    target: "_blank",
    icon: (
      <Box pad="small">
        <Paint size="medium" />
      </Box>
    ),
  },

  {
    label: (
      <Box pad="small" alignSelf="center" background={"white"}>
        Ayberk Helvacı
      </Box>
    ),
    color: "black",
    href: "https://ayberk.info",
    target: "_blank",
    icon: (
      <Box pad="small">
        <Paint size="medium" />
      </Box>
    ),
  },

  {
    label: (
      <Box pad="small" alignSelf="center" background={"red"}>
        Yaşar Kantarcılar
      </Box>
    ),
    color: "black",
    href: "https://www.linkedin.com/in/ya%C5%9Far-kantarcilar-646117244",
    target: "_blank",
    icon: (
      <Box pad="small">
        <Code size="medium" />
      </Box>
    ),
  },
  {
    label: (
      <Box pad="small" alignSelf="center" background={"turquoise"}>
        Berk Şamiloğlu
      </Box>
    ),
    color: "black",
    href: "https://github.com/berkSamil",
    target: "_blank",
    icon: (
      <Box pad="small">
        <Code size="medium" />
      </Box>
    ),
  },
];

const jobs = [
  {
    label: (
      <Box pad="small" alignSelf="center" background={"black"}>
        vacancies
      </Box>
    ),
    color: "black",
    href: "https://www.linkedin.com/company/start-a-world-peace/jobs/",
    target: "_blank",
  },
  {
    label: (
      <Box pad="small" alignSelf="center" background={"black"}>
        bounties
      </Box>
    ),
    color: "black",
    href: "https://app.dework.xyz/statefulart",
    target: "_blank",
  },
];

const events = [
  {
    label: (
      <Box pad="small" alignSelf="center" background={"black"}>
        build in public
      </Box>
    ),
    color: "black",
    href: "https://discord.gg/z8C6UZSKTs",
    target: "_blank",
  },
 
];

export const NavBar = () => (
  <Header background="black" pad="medium" height="xsmall">
    <ResponsiveContext.Consumer>
      {(size) =>
        size === "small" ? (
          <>
            <Image src="./start_.png" style={{ maxWidth: "24%" }}></Image>
            <Menu
              label="people"
              style={{
                fontWeight: "bolder",
                fontSize: "1rem",
                backgroundColor: "black",
                color: "white",
                borderRadius: "10px",
                justifySelf: "center",
              }}
              dropProps={{
                align: { top: "bottom", left: "left" },
                background: "black",
                margin: "1rem",
                pad: "0.4rem",
              }}
              items={shuffleTeam(ourTeam)}
            />

         

            <Box justify="end">
              <Menu
                a11yTitle="Navigation Menu"
                dropProps={{
                  align: { top: "bottom", right: "right" },
                  color: "black",
                  opacity: "medium",
                  background: "white",
                }}
                icon={<MenuIcon color="white" />}
                items={[
                  {
                    label: <Box pad="small">community</Box>,
                    color: "black",

                    href: "https://discord.gg/EXN7Mta2wu",
                    target: "_blank",
                  },

                  {
                    label: <Box pad="small">build in public</Box>,
                    color: "black",
                    href: "https://discord.gg/z8C6UZSKTs",
                  },

                  {
                    label: <Box pad="small">jobs</Box>,
                    color: "black",
                    href: "https://www.linkedin.com/company/start-a-world-peace/jobs/",
                  },

                  {
                    label: <Box pad="small">bounties</Box>,
                    color: "black",
                    href: "https://app.dework.xyz/statefulart",
                  },
                  {
                    label: <Box pad="small">code</Box>,
                    color: "black",
                    href: "https://github.com/stateful-art",
                  },
                ]}
              />
            </Box>
          </>
        ) : (
          <>
            <Image src="./start_.png" style={{ maxWidth: "16%" }}></Image>

            <Box justify="end" direction="row" gap="large">
              <Menu
                label="people"
                style={{
                  fontWeight: "bolder",
                  fontSize: "2rem",
                  backgroundColor: "black",
                  color: "white",
                  borderRadius: "10px",
                  lineHeightStep: "small",
                }}
                dropProps={{
                  align: { top: "bottom", left: "left" },
                  background: "black",
                }}
                items={shuffleTeam(ourTeam)}
              />
              <Menu
                label="events"
                style={{
                  fontWeight: "bolder",
                  fontSize: "2rem",
                  backgroundColor: "black",
                  color: "white",
                  borderRadius: "10px",
                  lineHeightStep: "small",
                }}
                dropProps={{
                  align: { top: "bottom", left: "left" },
                  background: "black",
                }}
                items={shuffleTeam(events)}
              />

              <Menu
                label="jobs"
                style={{
                  fontWeight: "bolder",
                  fontSize: "2rem",
                  backgroundColor: "black",
                  color: "white",
                  borderRadius: "10px",
                  lineHeightStep: "small",
                }}
                dropProps={{
                  align: { top: "bottom", left: "left" },
                  background: "black",
                }}
                items={jobs}
              />

              <Box background="black" pad="small" round="small" flex="false">
                <Anchor
                  href="https://discord.gg/EXN7Mta2wu"
                  target="_blank"
                  label="community"
                  color="white"
                  icon={<Chat />}
                />
              </Box>
            </Box>
          </>
        )
      }
    </ResponsiveContext.Consumer>
  </Header>
);

const shuffleTeam = (array) => {
  const shuffledArray = [...array]; // Create a copy of the original array
  for (let i = shuffledArray.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
  }
  return shuffledArray;
};

export default NavBar;