import {
  Anchor,
  Box,
  Grommet,
  Image,
  Paragraph,
  ResponsiveContext,
  Text,
} from "grommet";
import React from "react";
import CookieConsent from "react-cookie-consent";
import { NavBar } from "./components/commons/Navbar";

const theme = {
  global: {
    font: { family: "Open Sans", size: "18px", height: "20px" },
    background: { color: "#323001" },
  },
};

function NeuApp() {
  // const payload = {
  //   data: [
  //     {
  //       event_name: "ViewContent",
  //       event_time: 1716188577,
  //       action_source: "website",
  //       user_data: {
  //         em: [
  //           "7b17fb0bd173f625b58636fb796407c22b3d16fc78302d79f0fd30c2fc2fc068",
  //         ],
  //         ph: [null],
  //       },
  //     },
  //   ],
  // };

  // useEffect(() => {
  //   axios
  //     .post(`https://graph.facebook.com/v19.0/${pixel_id}/events`, payload, {
  //       params: {
  //         access_token: access_token,
  //       },
  //     })
  //     .then((response) => {
  //       console.log("Response:", response.data);
  //     })
  //     .catch((error) => {
  //       console.error("Error:", error.response.data);
  //     });
  // }, []);

  return (
    <Grommet theme={theme}>
      <ResponsiveContext.Consumer>
        {(size) =>
          size === "small" ? (
            <>
              <NavBar />
              <Box
                align="center"
                background="black"
                justify="center"
                direction="column"
              >
                <IntroTextVertical />
              </Box>

              <FooterSectionMobile />
            </>
          ) : (
            <>
              <NavBar />

              <Box
                align="center"
                background="black"
                justify="center"
                direction="column"
              >
                <IntroTextHorizontal />

                <FooterSection />
              </Box>
            </>
          )
        }
      </ResponsiveContext.Consumer>
    </Grommet>
  );
}

const FooterSectionMobile = () => (
  <>
    <Box direction="column" background={"black"}>
      <Box align="center" justify="center" margin={"12px"}>
        <Text size="small" weight={"bolder"}>
          {" "}
          contact@stateful.art
        </Text>
      </Box>

      <Box align="center" justify="center" margin={"16px"}>
        <Text size="small" weight={"bolder"} textAlign="center">
          {" "}
          st
          <Text color="red" size="small">
            art
          </Text>{" "}
          a World Peace Technology A.Ş. <br></br> © all rights acknowledged{" "}
          <br></br>
          <br></br>
          <hr></hr>
          <Text size="small" textAlign="start">
            07230 Muratpaşa, Antalya, Türkiye
          </Text>
          <br></br>
          <Text size="small" textAlign="start">
            VAT: 07811072493
          </Text>
        </Text>
      </Box>
    </Box>
  </>
);

const FooterSection = () => (
  <>
    <Box direction="column">
      <Box
        tag="footer"
        direction="row"
        justify="center"
        pad="small"
        gap="small"
        //flex={true}
        background="black"
      ></Box>

      <Box
        tag="footer"
        direction="row"
        justify="center"
        pad="small"
        gap="small"
        //flex={true}
        background="black"
      ></Box>

      <Box
        tag="footer"
        direction="row"
        justify="center"
        pad="small"
        gap="small"
        margin="medium"
        //flex={true}
        background="white"
      >
        {/* <Anchor
          href="https://docs.google.com/document/d/1jlLlCjQPUGdKhGcryRwcSOh7U_v1uOQlS9_wTvdxmx0/edit?usp=sharing"
          target="_blank"
          color="white"
        >
          <Text size="large">short paper {" | "}</Text>
        </Anchor> */}

        <Anchor
          href="https://discord.gg/EXN7Mta2wu"
          target="_blank"
          color="black"
        >
          <Text size="large">community</Text>
        </Anchor>

        {/* <Anchor
          href="https://miro.com/app/board/o9J_l3gy0-U=/"
          target="_blank"
          color="black"
        >
          <Text size="large">imagination</Text>
        </Anchor> */}

        {"|"}
        <Anchor
          href="https://github.com/stateful-art"
          target="_blank"
          color="black"
        >
          <Text size="large">code</Text>
        </Anchor>
        {/* 

        {"|"}
        <Anchor
          href="https://docs.google.com/spreadsheets/d/1JmXk2flQ_hFml1RfDyEld_qtNCpk4Vbb9wJHwQOCSHI/edit?usp=sharing"
          target="_blank"
          color="black"
        >
          <Text size="large">our tech radar</Text>
        </Anchor> */}

        {/* {"|"}

        <Anchor
          href="https://twitter.com/StatefulArt"
          target="_blank"
          color="white"
        >
          {" "}
          <Text size="large">twitter</Text>
        </Anchor> */}
      </Box>
      {/* 
      <Box align="center" justify="center" margin="30p" background="red">
      <Anchor
      href="https://trello.com/b/DzBmDMd9/statefulart"
      target="_blank"
      color="white"
      >
      {" "}
      <Text size="  large">project evolution</Text>
      </Anchor>
    </Box> */}

      <Box align="center" justify="center" margin="12px">
        <Text size="medium"> contact@stateful.art</Text>
      </Box>
      <Box align="center" justify="center">
        {/* <Anchor
          href="https://aworldpeace.org"
          target="_blank"
          >
          <Paragraph size="xlarge"> Art of Peace</Paragraph>
        </Anchor> */}
        <Box align="center" justify="center" margin="16px">
          <Text size="medium" weight={"bolder"}>
            {" "}
            st
            <Text color="red" size="medium">
              art
            </Text>{" "}
            a World Peace Technology A.Ş. © all rights acknowledged{" "}
          </Text>
          <hr style={{ width: "100%", marginTop: "1.2rem" }}></hr>

          <Text size="medium" marginTop="small" textAlign="center">
            07230 Muratpaşa, Antalya, Türkiye
          </Text>
          <Text size="medium" textAlign="center">
            VAT: 07811072493
          </Text>
        </Box>
      </Box>
    </Box>

    <CookieConsent
      location="bottom"
      buttonText="thanks"
      cookieName="no-cookie"
      style={{ background: "white", color: "black", fontSize: "1rem" }}
      buttonStyle={{ color: "#4e503b", fontSize: "12px", fontWeight: "bold" }}
      expires={60}
    >
      <span style={{ fontSize: "0.8rem" }}>
        We do <strong>not</strong> use cookies or collect any data to enhance
        your experience.
      </span>
    </CookieConsent>
  </>
);

const IntroTextHorizontal = () => {
  return (
    <>
      <Box margin="3rem">
        <Text size="40px">Art remembers</Text>
      </Box>
      <Box direction="row" size="large" margin={"2rem"}>
        <Box size="medium">
          <Paragraph textAlign="start" size="large">
            st
            <Text color="red" size="large">
              art
            </Text>{" "}
            is a forward-thinking technology company with a unique and enduring
            vision, Mutual Assured Regeneration. Aka.{" "}
            <Anchor
              size="large"
              href="https://aworldpeace.org"
              target="_blank"
              color="#98bdff"
            >
              <strong>"World Peace"</strong>
            </Anchor>
            <Text size="large"> </Text>
          </Paragraph>
        </Box>

        <Box size="medium" marginLeft="2rem">
          <Paragraph textAlign="end" size="large">
            We have initiated multiple projects at the crossroads of arts,
            rights and technology.
          </Paragraph>
        </Box>
      </Box>

      {/* <Box background={"black"} pad={"0px"}>
        <a href="https://sdgs.un.org/goals" target="_blank" rel="noopener">
          <img
            width={"240px"}
            src="https://sdgs.un.org/themes/custom/porto/assets/goals/global-goals.png"
          ></img>
        </a>
      </Box> */}

      <Paragraph size="large" textAlign="center">
        We prioritize socio-economic, environmental and artistic solutions
        through our in-house projects and consulting with design thinking
        approach.
      </Paragraph>

      <hr style={{ width: "20%", marginTop: "4rem" }}></hr>
      <Text
        style={{
          alignSelf: "center",
          marginTop: "0.5rem",
          marginBottom: "0.5rem",
          fontSize: "2rem",
        }}
      >
        Our Client Works
      </Text>
      <hr style={{ width: "20%", marginBottom: "4rem" }}></hr>
      <Box alignSelf="center" direction="row" overflow={"hidden"} gap="medium">
        <Box direction="column">
          <Box>
            <Anchor
              href="https://2050vision.org"
              target={"_blank"}
              rel="noopener"
            >
              <RM2050 />
            </Anchor>
          </Box>
          <Paragraph size="large" textAlign="center">
            The Rising Majority's <br></br> 2050 Vision
            <br></br>
            <br></br>
            <span style={{ fontStyle: "italic", fontSize: "medium" }}>
              A manifest for the future
            </span>
          </Paragraph>
        </Box>

        <Box direction="column">
          <Box>
            <Anchor
              href="https://dossiers.works"
              target={"_blank"}
              rel="noopener"
            >
              <Dossiers />
            </Anchor>
          </Box>
          <Paragraph size="large" textAlign="center">
            Dossiers
            <br></br>
            <br></br>
            <span style={{ fontStyle: "italic", fontSize: "medium" }}>
              Custom auction house dApp <br></br> for ERC6551 NFTs
            </span>
          </Paragraph>
        </Box>

        <Box direction="column">
          <Box>
            <Anchor
              href="https://justaddmeta.com/projects/summerjam"
              target={"_blank"}
              rel="noopener"
            >
              <SUMMERJAM />
            </Anchor>
          </Box>

          <Paragraph size="large" textAlign="center">
            SUMMERJAM NFT
            <br></br>
            <br></br>
            <span style={{ fontStyle: "italic", fontSize: "medium" }}>
              NFT minter dApp <br></br> for a metaverse consultancy firm
            </span>
          </Paragraph>
        </Box>
      </Box>

      <hr style={{ width: "20%", marginTop: "4rem" }}></hr>
      <Text
        style={{
          alignSelf: "center",
          marginTop: "0.5rem",
          marginBottom: "0.5rem",
          fontSize: "2rem",
        }}
      >
        In-house Projects
      </Text>
      <hr style={{ width: "20%", marginBottom: "4rem" }}></hr>
      <Box alignSelf="center" direction="row" overflow={"hidden"} gap="medium">
        <Box direction="column">
          <Box>
            <Anchor href="https://aworldpeace.org" target={"_blank"}>
              <AWorldPeace />
            </Anchor>
          </Box>
          <Paragraph size="large" textAlign="center">
            A World Peace
            <br></br>
            <br></br>
            <span style={{ fontStyle: "italic", fontSize: "medium" }}>
              Mutual Assured Regeneration
            </span>
          </Paragraph>
        </Box>

        <Box pad={"large"}>
            <Anchor href="https://diplomacy.network" target={"_blank"}>
              <DiplomacyNetwork />
            </Anchor>
          </Box>

        <Box direction="column">
          <Box>
            <Anchor
              href="https://expressionsofpeace.org"
              target={"_blank"}
              rel={"noreferrer"}
            >
              <ExpressionsOfPeace />
            </Anchor>
          </Box>
          <Paragraph size="large" textAlign="center">
            Expressions of Peace
            <br></br>
            <br></br>
            <span style={{ fontStyle: "italic", fontSize: "medium" }}>
              Peer to peer diplomacy scaler
            </span>
          </Paragraph>
        </Box>
          
        
      </Box>
      <Box direction="row" gap="xlarge">
      <Box direction="column">
          <Box>
            <Anchor href="https://newsquare.media" target={"_blank"}>
              <NewNewMedia />
            </Anchor>
          </Box>
          <Paragraph size="large" textAlign="center">
            {/* NewNew Media
            <br></br>
            <br></br> */}
            <span style={{ fontStyle: "italic", fontSize: "medium" }}>
              New Square Media
            </span>
          </Paragraph>
        </Box>
        <Box direction="column">
          <Box
            style={{
              marginTop: "18%",
              // marginLeft: "12%",
            }}
          >
            <Anchor href="https://naively.me" target={"_blank"}>
              <NaiveAI />
            </Anchor>
          </Box>
          <Paragraph size="large" textAlign="center">
            <span style={{ fontStyle: "italic", fontSize: "medium" }}>
              Naive AI
            </span>
            <br></br>
          </Paragraph>
        </Box>

        <Box direction="column">
          <Box
            style={{
              marginTop: "18%",
              // marginLeft: "12%",
            }}
          >
            <Anchor href="https://quickdoma.in" target={"_blank"}>
              <QuickDomain />
            </Anchor>
          </Box>
          <Paragraph size="large" textAlign="center">
            <span style={{ fontStyle: "italic", fontSize: "medium" }}>
              QuickDomain AI
            </span>
            <br></br>
          </Paragraph>
        </Box>
      </Box>

      {/* <Box pad="small" direction="column">
        <Paragraph textAlign="center" size="large">
          As an artwork itself, st
          <Text color="red" size="large">
            art
          </Text>{" "}
          exists in multi-states. <br></br>
          <br></br>
          We are calling them{" "}
          <strong>
            <Text color="red" size="large">
              D
            </Text>
            ecentralized{" "}
            <Text color="red" size="large">
              A
            </Text>
            rtistic{" "}
            <Text color="red" size="large">
              O
            </Text>
            rganicities
          </strong>{" "}
          : an expressive & progressive art direction in decentralized{" "}
          governance & tokenomics.
        </Paragraph>
      </Box> */}
    </>
  );
};

const IntroTextVertical = () => {
  return (
    <Box direction="column" size="large" margin="10%">
      <Box>
        <Text size="xxlarge" textAlign="center" margin={"medium"}>
          Art remembers
        </Text>
      </Box>

      <Box size="medium">
        <Paragraph textAlign="center" size="medium">
          st
          <Text color="red" size="medium">
            art
          </Text>{" "}
          is a forward-thinking technology company with a unique and enduring
          vision, Mutual Assured Regeneration. Aka.{" "}
          <Anchor
            size="medium"
            href="https://aworldpeace.org"
            target="_blank"
            rel="noopener"
            color="#98bdff"
          >
            <strong> "World Peace"</strong>
          </Anchor>
        </Paragraph>
      </Box>

      <Box size="medium">
        <Paragraph size="medium" textAlign="center">
          We have initiated multiple projects at the crossroads of arts, rights
          and technology.
          <br></br> <br></br>
        </Paragraph>
      </Box>
      <Box size="medium">
        {/* <Box background={"black"} pad={"60px"}>
          <a href="https://sdgs.un.org/goals" target="_blank" rel="noopener">
            <img
              width={"240px"}
              src="https://sdgs.un.org/themes/custom/porto/assets/goals/global-goals.png"
            ></img>
          </a>
        </Box> */}
        <Paragraph textAlign="center" size="medium">
          We prioritize socio-economic and environmental and artistic solutions
          through our in-house projects and consulting; with design thinking
          approach.
        </Paragraph>
      </Box>
      <Box alignSelf="center" margin={"2rem"}>
        <hr style={{ width: "100%", marginTop: "1rem" }}></hr>
        <Text
          style={{
            alignSelf: "center",
            marginTop: "0.2rem",
            marginBottom: "0.2rem",
            fontSize: "1.6rem",
          }}
        >
          Our Client Works
        </Text>
        <hr style={{ width: "100%" }}></hr>

        <Box alignSelf="start">
          <Box direction="column" margin={"20px 0px"}>
            <Anchor href="https://2050vision.org" target={"_blank"}>
              <RM2050Mobile />
            </Anchor>
            <Paragraph size="medium" textAlign="center">
              The Rising Majority's <br></br> 2050 Vision
            </Paragraph>
          </Box>

          <Box direction="column" margin={"40px 0px"}>
            <Anchor href="https://dossiers.works" target={"_blank"}>
              <DossiersMobile />
            </Anchor>
            <Paragraph size="medium" textAlign="center">
              Dossiers NFT
            </Paragraph>
          </Box>

          <Box direction="column" margin={"40px 0px"}>
            <Anchor
              href="https://justaddmeta.com/projects/summerjam"
              target={"_blank"}
            >
              <SUMMERJAMMobile />
            </Anchor>
            <Paragraph size="medium" textAlign="center">
              SUMMERJAM NFT
            </Paragraph>
          </Box>
        </Box>
      </Box>

      <Box alignSelf="center" margin={"2rem"}>
        <hr style={{ width: "100%", marginTop: "1rem" }}></hr>
        <Text
          style={{
            alignSelf: "center",
            marginTop: "0.2rem",
            marginBottom: "0.2rem",
            fontSize: "1.6rem",
          }}
        >
          In-house Projects
        </Text>
        <hr style={{ width: "100%" }}></hr>

        <Box alignSelf="start">
          <Box direction="column" margin={"20px 0px"}>
            <Anchor href="https://aworldpeace.org" target={"_blank"}>
              <AWorldPeaceMobile />
            </Anchor>
            <Paragraph size="medium" textAlign="center">
              A World Peace
            </Paragraph>
          </Box>

          <Box direction="column" margin={"20px 0px"}>
            <Anchor href="https://diplomacy.network" target={"_blank"}>
              <DiplomacyNetworkMobile />
            </Anchor>
           
          </Box>
          

          <Box direction="column" margin={"20px 0px"}>
            <Anchor href="https://expressionsofpeace.org" target={"_blank"}>
              <ExpressionsOfPeaceMobile />
            </Anchor>
            <Paragraph size="medium" textAlign="center">
              Expressions of Peace
            </Paragraph>
          </Box>
        </Box>
        <Box direction="column" margin={"20px 0px"}>
          <Anchor href="https://newsquare.media" target={"_blank"}>
            <NewNewMediaMobile />
          </Anchor>
          <Paragraph size="large" textAlign="center" margin={"40px 0px"}>
            {/* RestOrder App <br></br> */}
            <span style={{ fontStyle: "italic", fontSize: "medium" }}>
              New Square Media
            </span>
            <br></br>
            <br></br>
          </Paragraph>
        </Box>

        <Box direction="column" margin={"60px 0px"}>
          <Anchor href="https://naively.me" target={"_blank"}>
            <NaiveAIMobile />
          </Anchor>
          <Paragraph size="large" textAlign="center" margin={"40px 0px"}>
            <span style={{ fontStyle: "italic", fontSize: "medium" }}>
              Naive AI
            </span>
            <br></br>
            <br></br>
          
          </Paragraph>
        </Box>

        <Box direction="column" margin={"60px 0px"}>
          <Anchor href="https://quickdoma.in" target={"_blank"}>
            <QuickDomainMobie />
          </Anchor>
          <Paragraph size="large" textAlign="center" margin={"40px 0px"}>
            <span style={{ fontStyle: "italic", fontSize: "medium" }}>
              QuickDomain AI
            </span>
            <br></br>
            <br></br>
          
          </Paragraph>
        </Box>
      </Box>
    </Box>
  );
};

// const CitiesUnionByGenres = () => {
//   return (
//     <>
//       <Image
//         src="cities_bridged_by_genres.png"
//         width="600x"
//         height="400px"
//         margin={"40px"}
//       />
//     </>
//   );
// };

const Dossiers = () => {
  return (
    <Image
      alignSelf="center"
      src="./works/dossiers.png"
      width="200px"
      height="200px"
      margin={"36px"}
    />
  );
};

const RM2050 = () => {
  return (
    <Image
      alignSelf="center"
      src="./works/RM2050.png"
      width="268px"
      height="268px"
      // margin={"40px"}
    />
  );
};

const SUMMERJAM = () => {
  return (
    <Image
      alignSelf="center"
      src="./works/summerjam.png"
      width="268px"
      height="268px"
      // margin={"40px"}
    />
  );
};

const NewNewMediaMobile = () => {
  return (
    <>
      <Image
        alignSelf="center"
        justify="center"
        src="newnewmedia.png"
        width="268px"
      />
     
    </>
  );
};

const DiplomacyNetworkMobile = () => {
  return (
    <>
      <Image
        alignSelf="center"
        justify="center"
        src="./in-house-projects/diplomacy-network.png"
        width="268px"
      />
     
    </>
  );
};

const QuickDomainMobie = () => {
  return (
    <>
      <Image
        alignSelf="center"
        justify="center"
        src="./in-house-projects/quickdomain.png"
        width="200px"
        // height="280px"
        margin={"20px"}
      />
    </>
  );
};

const NaiveAIMobile = () => {
  return (
    <>
      <Image
        alignSelf="center"
        justify="center"
        src="./in-house-projects/naiveai.png"
        width="200px"
        // height="280px"
        margin={"30px"}
      />
    </>
  );
};
const SUMMERJAMMobile = () => {
  return (
    <>
      <Image
        alignSelf="center"
        justify="center"
        src="./works/summerjam.png"
        width="268px"
      />
    </>
  );
};

const RM2050Mobile = () => {
  return (
    <>
      <Image
        alignSelf="center"
        justify="center"
        src="./works/RM2050.png"
        width="268px"
      />
    </>
  );
};

const NewNewMedia = () => {
  return (
    <Image
      alignSelf="center"
      src="newnewmedia.png"
      maxWidth="268px"
      height="268px"
    />
  );
};

const DiplomacyNetwork = () => {
  return (
    <Image
      alignSelf="center"
      src="./in-house-projects/diplomacy-network.png"
      // maxWidth="120px"
      height="160px"
    />
  );
};

const QuickDomain = () => {
  return (
    <>
      <Image
        alignSelf="center"
        // justify="center"
        src="./in-house-projects/quickdomain.png"
        // width="268px"
        width="192px"
        // height="280px"
        margin={"12px 60px"}
      />
    </>
  );
};

const NaiveAI = () => {
  return (
    <>
      <Image
        alignSelf="center"
        // justify="center"
        src="./in-house-projects/naiveai.png"
        // width="268px"
        width="192px"
        // height="280px"
        margin={"12px 60px"}
      />
    </>
  );
};

const ExpressionsOfPeace = () => {
  return (
    <Image
      alignSelf="center"
      src="eop.png"
      width="248px"
      height="248px"
      // margin={"10px"}
      margin={"12px 60px"}
    />
  );
};

const ExpressionsOfPeaceMobile = () => {
  return (
    <Image
      alignSelf="center"
      src="eop.png"
      width="200px"
      height="200px"
      justify="center"
      margin={"30px"}
    />
  );
};

const AWorldPeace = () => {
  return (
    <Image
      alignSelf="center"
      src="peace_logo.png"
      width="268px"
      height="268px"
      // margin={"40px"}
    />
  );
};

const AWorldPeaceMobile = () => {
  return (
    <Image
      alignSelf="center"
      src="peace_logo.png"
      width="200px"
      height="200px"
      justify="center"
      margin={"30px"}
    />
  );
};

const DossiersMobile = () => {
  return (
    <Image
      alignSelf="center"
      src="./works/dossiers.png"
      width="200px"
      height="200px"
      justify="center"
      margin={"30px"}
    />
  );
};

export default NeuApp;
